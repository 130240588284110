//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;


//Font-sizes
$middle-size: 15px;
$small-size: 13px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;


.PasswordChangingForm {
  width: 450px;
  display: flex;
  flex-direction: column;
  padding: 30px;
  background-color: white;
  border: 1px solid #E6E6E6;
  box-shadow: 0px 2px 25px rgba(208, 201, 201, 0.5);
  .wrapper {
    position: relative;
    margin-bottom: 20px;
  }
  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .title {
    font-size: 18px;
    color: #4A4A4A;
    margin-bottom: 10px;
  }
  .description {
    font-size: 13px;
    color: #9B9B9B;
    margin-bottom: 20px;
  }
  .label {
    font-size: 13px;
    color: #4A4A4A;
  }
  input {
    width: 250px;
    height: 36px;
    font-size: 13px;
    color: #4A4A4A;
    background-color: #FAFAFA;
    border: 1px solid #E6E6E6;
    border-radius: 2px;
    &.invalid {
      border-color: #D0021B;
    }
  }
  .error {
    position: absolute;
    font-size: 11px;
    color: #D0021B;
  }
  .divider {
    border-top: 1px solid #D8D8D8;
    opacity: 0.5;
    margin-bottom: 20px;
  }
  button {
    width: 115px;
    height: 40px;
    background-color: #4A4A4A;
    color: white;
    border: none;
    text-transform: uppercase;
    font-size: 13px;
  }
  .link {
    color:  #9B9B9B;
    font-size: 12px;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
 
}