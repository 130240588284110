//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;


//Font-sizes
$middle-size: 15px;
$small-size: 13px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;


.AdmissionsApplication {
  .pageWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    .backButtonWrapper {
      width: 1160px;
      height: 60px;
      padding: 10px;
      background-color: white;
    }
    .line {
      border-bottom: 1px solid #D8D8D8;
    }
  }
}