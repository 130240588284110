//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;


//Font-sizes
$middle-size: 15px;
$small-size: 13px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;


.wrapper {
  position: relative;
  min-height: calc(100vh - 280px);
}
.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 52px;
}
.content {
  display: flex;
}
.variables {
  width: 400px;
  font-size: 13px;
  padding-right: 100px;
}
.variable {
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &Name {
    color: #9b9b9b;
  }
  &Value {
    color: rgb(74, 74, 74);
  }
}
.template {
  border-left: 1px solid #d8d8d8;
  padding-left: 100px;
}
.editor {
  width: 756px;
}
