//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;


//Font-sizes
$middle-size: 15px;
$small-size: 13px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;


.ApplicationFormTable {
  border-collapse: collapse;
  th,
  td {
    text-align: center;
    padding: 15px 0;
    font-size: 13px;
    border-top: 1px solid #f1efee;
    border-bottom: 1px solid #f1efee;
  }
  th {
    color: #4a4a4a;
  }
  td {
    color: #9b9b9b;
  }
}
