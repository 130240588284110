//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;


//Font-sizes
$middle-size: 15px;
$small-size: 13px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;


.field_value {
  color: #4A4A4A;
}

.manager_button  {
    color: #9B9B9B;
  cursor: pointer;
}

.field {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  justify-content: space-between;
  width: 100%;
  max-width: 260px;
  font-size: 14px;
  height: 100%;
  align-items: center;
}