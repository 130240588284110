//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;


//Font-sizes
$middle-size: 15px;
$small-size: 13px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;


.wrapper {
  position: fixed;
  left: 50%;
  top: 5px;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 30px;
  background-color: $additional-color;
  color: white;
  height: 50px;
  border-radius: $small-radius;
}

.image {
  width: 15px;
  margin-right: 10px;
}
