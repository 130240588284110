//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;


//Font-sizes
$middle-size: 15px;
$small-size: 13px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;


.wrapper {
  position: relative;
}
.sectionsWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: calc(100vh - 260px);

  & > * {
    margin-bottom: 30px;
  }
  .tableWrapper {
    position: relative;
    .tableTitle {
      position: absolute;
      left: 10px;
      color: #4a4a4a;
      font-size: 20px;
      line-height: 40px;
    }
  }
}
