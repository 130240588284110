//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;


//Font-sizes
$middle-size: 15px;
$small-size: 13px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;


.wrapper {
  width: 400px;
  background-color: white;
  border: 1px solid #e6e6e6;
  box-shadow: 0px 2px 25px rgba(208, 201, 201, 0.5);
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 38px;
}
.text {
  padding: 10px;
  font-size: 13px;
  color: #4a4a4a;
}
.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;
  cursor: pointer;
}
.line {
  border-top: 1px solid #f1efee;
}
.main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 58px;
  padding: 0 10px;
}
.button {
  width: 110px;
  height: 38px;
  font-size: 13px;
  text-transform: uppercase;
  color: white;
  background-color: #4a4a4a;
  border: none;
  cursor: pointer;
}
