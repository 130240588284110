//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;


//Font-sizes
$middle-size: 15px;
$small-size: 13px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;


.inline {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  & > * {
    margin-right: 10px;
    &:last-child {
      margin-right: 0px;
    }
  }
}
