//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;


//Font-sizes
$middle-size: 15px;
$small-size: 13px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;


.PasswordRecoverySuccessForm {
  width: 350px;
  display: flex;
  flex-direction: column;
  padding: 30px;
  background-color: white;
  border: 1px solid #e6e6e6;
  box-shadow: 0px 2px 25px rgba(208, 201, 201, 0.5);
  .title {
    text-align: center;
    font-size: 30px;
    color: #4A4A4A;
    margin-bottom: 10px;
  }
  .text {
    text-align: justify;
    font-size: 13px;
    color: #9B9B9B;
  }
}
