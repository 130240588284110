//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;


//Font-sizes
$middle-size: 15px;
$small-size: 13px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;


.wrapper {
  position: relative;
  .input {
    position: relative;
    display: flex;
    align-items: center;
    height: 38px;
    background-color: #f5f5f5;
    border: 1px solid #d8d8d8;
    border-radius: 2px;
    cursor: pointer;
    &.disabled {
      cursor: not-allowed;
    }
    &.borderless {
      border: 1px solid transparent;
      border-radius: 0;
    }
    &.transparent {
      background-color: transparent;
    }
    .value {
      flex: 1;
      padding: 0 10px;
      height: 36px;
      line-height: 36px;
      font-size: $middle-size;
      color: $primary-color;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      &.empty {
        color: #9b9b9b;
      }
    }
    .spinner {
      position: absolute;
      right: 10px;
    }
    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0;
      min-width: 6px;
      margin-right: 10px;
      height: 100%;
    }
  }
  .popup {
    position: absolute;
    left: 0;
    top: 38px;
    z-index: 1;
    width: 100%;
    overflow-y: auto;
    background-color: #f1efee;
    box-shadow: 0px 2px 23px #e4e6e9;
    .search {
      position: relative;
      height: 42px;
      padding: 2px;
      background-color: white;
      .spinner {
        position: absolute;
        right: 0;
        top: 0;
        width: 42px;
        height: 42px;
      }
    }
    .option {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 38px;
      background-color: white;
      margin-bottom: 1px;
      font-size: 13px;
      color: #4a4a4a;
      overflow: hidden;
      white-space: nowrap;
      padding: 0 12px;
      &:last-child {
        margin-bottom: 0;
      }
      &.hover {
        &:hover {
          background-color: #f5f4f3;
          cursor: pointer;
        }
      }
    }
  }
}
.xIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12px;
  height: 12px;
}
