//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;


//Font-sizes
$middle-size: 15px;
$small-size: 13px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;


.TextInput {
  position: relative;
  display: flex;
  align-items: center;
  height: 38px;
  font-size: 13px;
  background-color: #f5f5f5;
  border: 1px solid #d8d8d8;
  border-radius: 2px;
  color: #4a4a4a;
  &.borderless {
    border: 1px solid transparent;
    border-radius: 0;
  }
  &.transparent {
    background-color: transparent;
  }
  .input {
    width: 100%;
    height: 100%;
    font-size: $middle-size;
    background-color: inherit;
    border: none;
    color: $primary-color;
    &::placeholder {
      color: #9b9b9b;
    }
  }
  .spinner {
    margin-right: 10px;
  }
  .error {
    position: absolute;
    left: 0;
    bottom: -12px;
    color: #d0021b;
    font-size: 10px;
  }
}
.eyeIcon {
  position: absolute;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
