//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;


//Font-sizes
$middle-size: 15px;
$small-size: 13px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;


.NotFoundPage {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f1efee;
  .content {
    width: 350px;
    .title {
      color: #9b9b9b;
      font-size: 39px;
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 5px;
    }
    .title-bold {
      color: #4A4A4A;
      font-size: 48px;
      font-weight: bold;
      text-align: center;
      margin-bottom: 15px;
    }
    .text {
      color: #9B9B9B;
      font-size: 12px;
      text-align: justify;
      margin-bottom: 25px;
    }
    .wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    button {
      width: 165px;
      height: 40px;
      border: none;
      font-size: 10px;
      text-transform: uppercase;
      color: #292929;
      background-color: white;
    }
  }
}
