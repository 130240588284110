//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;


//Font-sizes
$middle-size: 15px;
$small-size: 13px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;


.TableRulePopup {
  width: 450px;
  background-color: white;
  border: 1px solid #e6e6e6;
  box-shadow: 0px 2px 25px rgba(208, 201, 201, 0.5);
  padding: 15px;

  & > * {
    margin: 10px;
  }

  .titleWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px;
    .title {
      font-size: 13px;
      color: #4a4a4a;
    }
    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 38px;
      height: 38px;
      cursor: pointer;
    }
  }

  .line {
    border-top: 1px solid #f1efee;
  }

  .inputs {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .matches {
    font-size: 13px;
    color: #4a4a4a;
    margin-bottom: 5px;
  }
  .rule {
    display: flex;
    justify-content: space-between;
    align-items: center;
    input {
      width: 130px;
    }

    button {
      width: 46px;
      height: 38px;
      border: none;
      color: #9b9b9b;
      background-color: #f1efee;
      font-size: 13px;
    }
  }
  // .add {
  //   padding: 0 12px;
  //   span {
  //     font-size: 10px;
  //     line-height: 12px;
  //     color: #9b9b9b;
  //     cursor: pointer;
  //   }
  // }

  .footer {
    position: relative;
    height: 12px;
    font-size: 13px;
    .save {
      position: absolute;
      left: 12px;
      color: $additional-color;
      cursor: pointer;
    }
    .delete {
      position: absolute;
      right: 12px;
      color: #D0021B;
      cursor: pointer;
    }
  }
}
