//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;


//Font-sizes
$middle-size: 15px;
$small-size: 13px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;


.PageNavLinks, .PageNavSubLinks {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 60px;
  padding: 1px;
  border-radius: $small-radius;
  a {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    padding: 0 10px;
    font-size: $big-size;
    opacity: 0.75;
    &.active {
      opacity: 1;
      font-weight: bold;
      border-top: 3px solid transparent;
      border-bottom: 3px solid $additional-color;
    }
  }
}

.PageNavLinks {
  background-color: $primary-color;
  a {
    color: white;
  }
}

.PageNavSubLinks {
  background: $white-color;
  color: $primary-color;
  border: 1px solid $primary-color;
  a {
    color: $primary-color;
  }
}
