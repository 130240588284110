//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;


//Font-sizes
$middle-size: 15px;
$small-size: 13px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;


.HeaderUser {
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > * {
    margin-right: 15px;
    &:last-child {
      margin-right: 0;
    }
  }
  .divider {
    width: 0;
    height: 30px;
    border-left: 1px solid #d8d8d8;
  }
  .icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #ebebeb;
    cursor: pointer;
  }
  .name {
    max-width: 100px;
    color: #4a4a4a;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .log-out {
    color: #4a4a4a;
    font-size: 14px;
    cursor: pointer;
  }
}
