//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;


//Font-sizes
$middle-size: 15px;
$small-size: 13px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;


.wrapper {
  // margin: 20px 0;
  &>* {
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.inputWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buttonWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
}

.buttonSecondary {
  width: 80px;
  height: 30px;
  color: #9B9B9B;
  background: transparent;
  border: 1px solid transparent;
  font-size: 13px;
}