//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;


//Font-sizes
$middle-size: 15px;
$small-size: 13px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;


.RadioInputGroup {
  .wrapper {
    display: flex;
    align-items: center;
    height: 36px;
    cursor: pointer;
    .input {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 12px;
      height: 12px;
      position: relative;
      background-color: #efeeee;
      border: 1px solid #d5d5d5;
      border-radius: 50%;
      margin-right: 10px;
      .checkmark {
        width: 4px;
        height: 4px;
        background-color: $additional-color;
        border-radius: 50%;
      }
    }
    .label {
      color: #9b9b9b;
      font-size: 13px; 
      white-space: nowrap;
    }
    
    &.disabled {
      cursor: not-allowed;
    }
  }
}

.inline {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -10px;
  & > * {
    margin: 0 10px;
  }
}

