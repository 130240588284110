//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;


//Font-sizes
$middle-size: 15px;
$small-size: 13px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;


.FormWrapper {
  display: flex;
  flex-direction: column;
 
 
  background-color: white;
  // box-shadow: 0px 10px 25px rgba(188, 183, 183, 0.5);

  .main {
    flex: 1 1;
    position: relative;
    padding: 40px;
    .spinnerWrapper {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: rgba(74, 74, 74, 0.3);
    }
  }

  .footer {
    display: grid;
    align-items: center;
    padding: 0 40px;
    background-color: #fafafa;
    justify-content: center;
    padding: 15px 0;
    button {
      font-size: 13px;
      min-width: 115px;
      height: 40px;
    }
    .cancel {
      left: 40px;
      background: #ffffff;
      border: 1px solid #d8d8d8;
      text-transform: uppercase;
      color: #292929;
    }
    .delete {
      color: $negative-color;
      background: transparent;
      border: none;
      &::first-letter {
        text-transform: uppercase;
      }
    }
    .save {
      right: 40px;
      border: none;
      text-transform: uppercase;
      color: white;
      background-color: #4A4A4A;
    }
  }
}
