//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;


//Font-sizes
$middle-size: 15px;
$small-size: 13px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;


.DatePicker {
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 38px;
  background-color: #f5f5f5;
  border: 1px solid #d8d8d8;
  border-radius: 2px;
  color: #4a4a4a;
  &.disabled {
    cursor: not-allowed;
  }
  &.borderless {
    border: 1px solid transparent;
    border-radius: 0;
  }
  &.transparent {
    background-color: transparent;
  }
  
  .input {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: inherit;
    color: inherit;
    font-size: inherit;
    .value {
      display: flex;
      align-items: center;
      flex: 1;
      padding: 0 10px;
      height: 100%;
      font-size: $middle-size;
      color: $primary-color;
      overflow: hidden;
      white-space: nowrap;
      &.empty {
        color: #9b9b9b;
      }
    }
    .xIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 7px;
        height: 7px;
        margin-right: 3px;
      }
    }
    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0;
      min-width: 6px;
      margin-right: 10px;
      height: 100%;
    }
  }
  .popup {
    position: absolute;
    top: 38px;
    z-index: 1;
    width: 384px;
  }
}
.error {
  position: absolute;
  color: #D0021B;
  font-size: 10px;
  left: 0;
  bottom: -12px;
}
