//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;


//Font-sizes
$middle-size: 15px;
$small-size: 13px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;


.titleWrapper {
  display: grid;
  grid-template-columns: max-content 1fr;
  justify-content: space-between;
  align-items: center;
  height: 52px;
}

.wrapper {
}

.rowsWrapper {
  display: flex;
  flex-direction: column;
  width: 900px; 
}
.rowWrapper {
  position: relative;
  display: flex;
  align-items: center;
  height: 52px;
}
