//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;


//Font-sizes
$middle-size: 15px;
$small-size: 13px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;


.titleWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 52px;
}
.button {
  height: 40px;
  padding: 0 20px;
  background: #f1efee;
  border: 1px solid #d8d8d8;
  font-size: 14px;
    color: #9B9B9B;
}