//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;


//Font-sizes
$middle-size: 15px;
$small-size: 13px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;


.wrapper {
  position: relative;
}

.input {
  position: relative;
  display: flex;
  align-items: center;
  height: 38px;
  background-color: #f5f5f5;
  border: 1px solid #d8d8d8;
  border-radius: 2px;
  cursor: pointer;
  &.disabled {
    cursor: not-allowed;
  }
  &.borderless {
    border-color: transparent;
    border-radius: 0;
  }
  &.transparent {
    background-color: transparent;
  }
}

.values {
  display: flex;
  align-items: center;
  flex: 1;
  padding: 0 12px;
  height: 100%;
  font-size: $middle-size;
  color: $primary-color;
  overflow: hidden;
  white-space: nowrap;
  &.empty {
    color: #9b9b9b;
  }
}
.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0;
  min-width: 6px;
  margin-right: 10px;
  height: 100%;
}

.options {
  position: absolute;
  left: 0;
  top: 38px;
  z-index: 1;
  width: 100%;  
  overflow-y: auto;
  background-color: #F1EFEE;
  box-shadow: 0px 2px 23px #E4E6E9;
}

.option {
  display: flex;
  align-items: center;
  height: 38px;
  background-color: white;
  margin-bottom: 1px;
  overflow: hidden; 
  white-space: nowrap;
  padding: 0 12px;
  &:last-child {
    margin-bottom: 0;
  }
  &:hover {
    background-color: #f5f4f3;
  }
}


.errorMessage {
  position: absolute;
  color: #D0021B;
  font-size: 10px;
}
