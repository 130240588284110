//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;


//Font-sizes
$middle-size: 15px;
$small-size: 13px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;


.RuleRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    width: 46px;
    height: 38px;
    border: none;
    color: white;
    background-color: #4A4A4A;
    font-size: 13px;
  }
}
