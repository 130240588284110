//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;


//Font-sizes
$middle-size: 15px;
$small-size: 13px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;


.warning {
  display: flex;
  align-items: flex-start;
  background: #f9f9f9;
  padding: 10px;
}
.icon {
  margin: 10px;
  border: 4px solid #9b9b9b;
  border-radius: 50%;
  width: 66px;
  height: 66px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 42px;
  color: #9b9b9b;
}
.content {
  margin: 10px;
  flex: 1;
  font-size: 14px;
  color: #9b9b9b;
}
