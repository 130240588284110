//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;


//Font-sizes
$middle-size: 15px;
$small-size: 13px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;


.Tooltip {
  position: relative;
  .bubble {
    // min-width: 100px;
    // max-width: 250px;
    position: absolute;
    z-index: 10;
    &::after {
      content: "";
      position: absolute;
    }
  }
  .message {
    background: white;
    box-shadow: 0px 10px 44px rgba(132, 132, 132, 0.5);
    color: #4a4a4a;
    font-size: 12px;
    padding: 10px;
    // white-space: normal;
  }
  .top {
    bottom: 150%;
    left: 50%;
    padding-bottom: 9px;
    transform: translateX(-50%);
    &.withArrow::after {
      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
      border-top: 9px solid white;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .bottom {
    top: 150%;
    left: 50%;
    padding-top: 9px;
    transform: translateX(-50%);
    &.withArrow::after {
      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
      border-bottom: 9px solid white;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .left {
    top: 50%;
    right: 100%;
    padding-right: 9px;
    transform: translateY(-50%);
    &.withArrow::after {
      border-left: 9px solid white;
      border-top: 9px solid transparent;
      border-bottom: 9px solid transparent;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
  }
  .right {
    top: 50%;
    left: 100%;
    padding-left: 9px;
    transform: translateY(-50%);
    &.withArrow::after {
      border-right: 9px solid white;
      border-top: 9px solid transparent;
      border-bottom: 9px solid transparent;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }
}
