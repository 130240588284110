//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;


//Font-sizes
$middle-size: 15px;
$small-size: 13px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;


@import "./styles/_variables.scss";

@font-face {
  font-family: San-Francisco;
  src: url('./assets/fonts/SF-Pro-Display-Regular.otf');
}

* {
  box-sizing: border-box;
  outline: none;
  font-family:  San-Francisco, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

body {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}


button {
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
  }
}

input {
  padding: 0 10px;
  &:disabled {
    cursor: not-allowed;
  }
  &::-ms-clear {
    display: none;
  }
}
