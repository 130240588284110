//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;


//Font-sizes
$middle-size: 15px;
$small-size: 13px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;


.wrapper {
  width: 1160px;
  background-color: white;
  padding: 20px;
}
.sectionsWrapper {
  display: flex;
}
.section {
  margin: 20px;
  width: 520px;
}
.title {
  font-size: 20px;
  color: #4a4a4a;
  margin-bottom: 20px;
}
.fixedRow {
  position: relative;
  display: flex;
  align-items: center;
  height: 46px;
  margin: 10px 0;
}
.row {
  margin: 10px 0;
}
.text,
.note {
  display: flex;
  align-items: center;
  min-height: 46px;
  font-size: 13px;
  text-align: justify;
}
.text {
  color: #4a4a4a;
}
.note {
  color: #9b9b9b;
}
