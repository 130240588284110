//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;


//Font-sizes
$middle-size: 15px;
$small-size: 13px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;


.accordion {
  position: relative;
  width: 100%;
  height: 100%;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
}

.title {
  background: #FAFAFA;
  color: #4A4A4A;
  font-size: 13px;
  height: 38px;
  display: flex;
  align-items: center;
  padding-left: 10px;
}
.accordionWrapper {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 15px;
  width: 100%;
}

.error {
  position: absolute;
  left: 0;
  bottom: -12px;
  color: #d0021b;
  font-size: 10px;
}

.accordionWrapper {
}

.content {
  grid-row-gap: 14px;
  margin: 10px;
  &.isShow {
    display: grid;
  }

  &.isHide {
    display: none;
  }
}

.label {
  color: #7d7d7d;
  font-size: 12px;
}

.field {
  display: grid;
  grid-template-columns: 96px 1fr;
  align-items: center;
  grid-column-gap: 10px;
  height: 38px;
}

.margin_bottom {
  margin-bottom: 45px;
}
