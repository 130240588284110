//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;


//Font-sizes
$middle-size: 15px;
$small-size: 13px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;


.wrapper {
  position: relative;
  min-height: calc(100vh - 280px);
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 52px;
  }
}