//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;


//Font-sizes
$middle-size: 15px;
$small-size: 13px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;


.SectionTabs {
  position: relative;
  width: 295px;
  min-width: 295px;
  padding: 40px 1px;
  background-color: $primary-color;
  cursor: pointer;
  .tab {
    display: flex;
    align-items: center;
    padding: 0 36px;
    height: 42px;
    font-size: $big-size;
    color: white;
    opacity: 0.75;
    border-left: 3px solid transparent;
    &:last-child {
      border-bottom: 1px solid transparent;
    }
    &.shifted {
      padding-left: 52px;
    }
    &.active {
      border-left: 3px solid white;
      opacity: 1;
      // color: white;
      font-weight: bold;
      // background-color: #4A4A4A;
    }
  }
  
}
