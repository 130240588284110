//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;


//Font-sizes
$middle-size: 15px;
$small-size: 13px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;


.wrapper {
  position: relative;
}
// .backButtonWrapper {
//   height: 60px;
//   padding: 10px;
//   background-color: white;

//   border-color: #E6E6E6;
//   border-style: solid;

//   border-width: 1px 1px 0 1px;
// }
.sectionsWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: calc(100vh - 260px);
  & > *:not(:first-child) {
    margin-bottom: 30px;
  }
  .studentInfo {
    position: absolute;
    left: 140px;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    & > * {
      font-size: 13px;
      color: #4a4a4a;
      margin-right: 20px;
      span {
        font-weight: bold;
      }
    }
  }
}
