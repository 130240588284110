//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;


//Font-sizes
$middle-size: 15px;
$small-size: 13px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;


.Table {
  position: relative;
  // .cleanFilterWrapper {
  //   display: flex;
  //   justify-content: flex-end;
  //   padding: 0 10px 10px;
  //   .cleanFilter {
  //     cursor: pointer;
  //     font-size: 10px;
  //     font-weight: bold;
  //     color: #4A4A4A;
  //   }
  // }

  .cleanFilters {
    position: absolute;
    right: 10px;
    display: flex;
    align-items: center;

    height: 10px;
    cursor: pointer;
    font-size: 13px;
    font-weight: bold;
    color: #4a4a4a;
  }

  .tableBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // margin-bottom: 10px;
    // padding: 10px;
    height: 60px;
    background-color: white;
    border: 1px solid #d8d8d8;
  
    .tableBarLeft {
      flex: 1 1;
      display: flex;
      align-items: center;
      // margin: 0 -10px;
      overflow: hidden;
      & > * {
        margin: 0 10px;
      }

      .tilesWrapper {
        flex: 1 1;
        position: relative;
        // width: 870px;
        height: 58px;

        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 10px;
          width: 20px;
          height: 40px;
          background-image: linear-gradient(to right, white, transparent);
          z-index: 1;
        }
        &::after {
          content: "";
          position: absolute;
          right: 0;
          top: 10px;
          width: 20px;
          height: 40px;
          background-image: linear-gradient(to left, white, transparent);
          z-index: 1;
        }

        .tiles {
          display: flex;
          align-items: center;
          height: 60px;
          & > * {
            margin: 0 5px;
            &:first-child {
              margin-left: 20px;
            }
            &:last-child {
              border-right: 20px solid transparent;
            }
          }
        }
      }

      // .tiles {
      //   flex: 1 1;
      //   display: flex;
      //   & > * {
      //     margin-right: 10px;
      //     &:last-child {
      //       margin-right: 0;
      //     }
      //   }
      // }
    }
    .tableBarRight {
      display: flex;
      align-items: center;
      // margin: 0 -10px;

      & > * {
        margin-right: 10px;
      }
      .importButton {
        height: 40px;
        display: flex;
        align-items: center;
        padding: 0 12px;
        font-size: 13px;
        color: #292929;
        cursor: pointer;
      }
      .arrowButtons {
        display: flex;
        align-items: center;
        & > *:first-child {
          margin-right: 2px;
        }
      }
    }
  }
  .paginationContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    // margin-top: 10px;
    height: 60px;
    padding: 10px;
  }
}

// .rule {
//   background-color: goldenrod;
// }
