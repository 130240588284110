//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;


//Font-sizes
$middle-size: 15px;
$small-size: 13px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;


.DateRangePopup {
  position: relative;
}
.calendar-range {
  position: absolute;
  min-height: 311px;
  width: 384px;

  .rdrDayNumber span {
    color: #7c86a2 !important;
    font-size: 14.5366px;
    z-index: 2;
  }
  .rdrInRange {
    width: 89px;
    height: 32px;
    position: absolute;
    left: -20px;
    background-color: #f5f4f3;
    opacity: 0.69;
  }
  .rdrStartEdge,
  .rdrEndEdge {
    border-radius: 50% !important;
    height: 37px;
    width: 37px;
    left: 9px !important;
    top: 2px;
    color: #d3d3d3 !important;
    z-index: 1;
  }
  .rdrDayEndOfWeek .rdrInRange {
    width: 60px !important;
  }

  .rdrDayPassive span {
    opacity: 0.5;
  }

  .rdrStartEdge ~ .rdrDayNumber span,
  .rdrEndEdge ~ .rdrDayNumber span {
    color: #ffffff !important;
  }
  .rdrDayEndOfWeek .rdrInRange {
    width: 50px;
  }
  .rdrDayStartOfMonth .rdrInRange {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .rdrDayPassive .rdrStartEdge ~ .rdrDayNumber span {
    color: #6c7e9c !important;
  }

  .rdrPprevButton {
    position: relative;
    left: 270px;
    background: white;
    &:hover {
      background: white;
    }
    i {
      color: #000;
    }
  }
  .rdrNextButton {
    position: relative;
    left: 20px;
    background: white;
    &:hover {
      background: white;
    }
    i {
      color: #000;
    }
  }
  .rdrMonthAndYearPickers {
    flex: 1;
    justify-content: left;
    width: 200px;
    position: relative;
    right: 51px;
    color: #6b7897;
    font-size: 15px;
    font-weight: normal;
    letter-spacing: 0.59375px;
  }
  .rdrMonth {
    padding: 0;
    margin: 0 auto;
    width: 355px;
  }
  .rdrMonthAndYearWrapper {
    margin: 20px auto 0 auto;
    width: 324px;
    height: 30px;
    padding: 0;
  }
  .rdrWeekDays span {
    font-size: 15.6548px;
    color: #b8bfc6 !important;
  }
  .rdrDay,
  .rdrWeekDay {
    width: 50px !important;
  }
  .rdrDay {
    height: 35px;
  }
  .rdrDayNumber {
    position: relative;
    top: 2px;
    left: 1px;
  }
  .rdrDayToday .rdrDayNumber span:after {
    height: 0 !important;
  }
  .rdrDayStartPreview,
  .rdrDayEndPreview,
  .rdrDayInPreview {
    border-width: 0 !important;
  }

  .rdrDayStartOfWeek .rdrInRange{
   position: absolute;
    left:5px;
  }

}
