//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;


//Font-sizes
$middle-size: 15px;
$small-size: 13px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;


.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.contactUs {
  width: 1160px;
  background: #ffffff;
  border: 1px solid #D8D8D8;

  .contactUsTitle {
    margin-bottom: 20px;
    padding: 20px 40px 0 40px;
  }
  .contactUsContent {
    display: flex;
    .contactUsWrapper {
      width: 50%;
      height: calc(100% - 73px);
      border-top: 1px solid #D8D8D8;
      padding: 40px 75px;

      .info {
        display: flex;
        .contactInfo {
          margin-top: 20px;
          margin-right: 30px;
          color: #6c6c6c;
          & > * {
            margin-bottom: 10px;
            img {
              margin-right: 10px;
            }
          }
        }
        .smInfo {
          margin-top: 20px;
          & > * {
            margin-bottom: 10px;
            img {
              margin-right: 30px;
            }
          }
        }
      }

      .inputsWrapper {
        button {
          width: 155px;
          height: 40px;
          background-color: #4a4a4a;
          color: white;
          border: none;
          text-transform: uppercase;
          font-size: 13px;
        }
        & > * {
          margin: 35px 0;
        }
        .inputWrapper {
          position: relative;
          width: 410px;
          height: 38px;
          display: flex;
          & > * {
            position: absolute;
            &:nth-child(2) {
              left: 75px;
            }
          }
        }
      }
    }
  }
}
