//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;


//Font-sizes
$middle-size: 15px;
$small-size: 13px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;


.CheckboxInput {
  display: flex;
  align-items: center;
  cursor: pointer;
  &.disabled {
    cursor: not-allowed;
  }
  margin: 0 -5px;
  & > * {
    margin: 0 5px;
  }

  .input {
    width: 12px;
    height: 12px;
    position: relative;
    background-color: #efeeee;
    border: 1px solid #d8d8d8;
    border-radius: 2px;
    .checkmark {
      position: absolute;
      left: 4px;
      top: -4px;
      width: 6px;
      height: 12px;
      border: solid $additional-color;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }
  .label {
    color: #9B9B9B;
    font-size: 13px;
  }
}
