//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;


//Font-sizes
$middle-size: 15px;
$small-size: 13px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;


.modal_actions {
  font-size: 14px;
  display: grid;
  grid-template-columns: repeat(2, max-content);
  justify-content: end;
  margin-right: 41px;
  grid-column-gap: 8px;
  margin-top: 40px;
  button {
    padding: 10px 16px;
    outline: none;
    border: 0;
  }
}
.button_cancel {
  background: #F1EFEE;
  color: #9B9B9B;
}

.button_save {
  background: #4A4A4A;
  color: #ffffff;
}

.modal_position {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0, .5);
  z-index: 100;
}

.modal {
  background: white;
  max-width: 780px;
  width: 100%;
  position: absolute;
  z-index: 1000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 40px;

  &_fields {
    height: 240px;
    &_wrapper {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__category {
    &_tick {
      width: 10px;
    }
  }

  &_search_field {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    label {
      margin-right: 10px;
    }
  }
  &_field {
    margin: 4px;
    padding: 8px 14px;
    border-radius: 4px;
    cursor: pointer;
    img {
      margin-left: 10px;
      cursor: pointer;
    }

    &_active {
      color: #4a4a4a;
      background: #e6e6e6;
      border-radius: 4px;
    }

    &_removed {
      color: #9b9b9b;
      border: 1px dashed $additional-color;
    }
  }
}

.modal_title {
  font-size: 24px;
  font-weight: 400;
  margin: 0;
  margin-bottom: 8px;
}

.modal_sub_title {
  color: #9B9B9B;
  font-size: 16px;
  margin: 0;
  margin-bottom: 10px;
}
