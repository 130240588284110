//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;


//Font-sizes
$middle-size: 15px;
$small-size: 13px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;


.wrapper {
  display: flex;
  flex-direction: column;
  font-size: 0;
  & > * {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.titleWrapper {
  display: flex;
  align-items: center;
  height: 42px;
  & > *:first-child {
    margin-right: 20px;
  }
}
.textareaWrapper {
  width: 900px;
}
.buttonsWrapper {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  justify-content: center;
  margin: 20px 0;
  grid-column-gap: 10px;
}
.buttonSecondary {
  width: 80px;
  height: 30px;
  font-size: 13px;
  background: #ffffff;
  border: 1px solid #d8d8d8;
  color: #292929;
}
.buttonMain {
  width: 80px;
  height: 30px;
  color: white;
  background-color: #4a4a4a;
  border: none;
  font-size: 13px;
}
.notesWrapper {
  display: flex;
  flex-direction: column-reverse;
}
.noteWrapper {
  display: flex;
  width: 900px;
  background-color: #f5f5f5;
  margin-bottom: 10px;
  &:first-child {
    margin-bottom: 0;
  }
}
.left {
  width: 60px;
  min-width: 60px;
  padding: 10px;
}
.right {
  flex: 1 1;
  padding: 10px;
}
.photo {
  width: 40px;
  height: 40px;
  background-color: #ebebeb;
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.author {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  justify-content: space-between;
  margin-bottom: 10px;
  color: #9b9b9b;
  font-size: 13px;
}
.text {
  color: #292929;
  margin-bottom: 2px;
  font-size: 13px;
  white-space: pre-wrap;
}
.message {
  display: flex;
  align-items: center;
  height: 42px;
  font-size: 13px;
  color: #9b9b9b;
}

.actions {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-column-gap: 15px;

  div {
    cursor: pointer;
  }
}

.edit {
  color: $additional-color;
}

.delete {
  color: $negative-color;
}
