//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;


//Font-sizes
$middle-size: 15px;
$small-size: 13px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;


.wrapper {
  padding: 40px;
  flex: 1 1;
  position: relative;

  .spinnerWrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(74, 74, 74, 0.3);
  }

  .titleWrapper {
    display: flex;
    // justify-content: space-between;
    align-items: center;
    height: 52px;
  }

  .addType{
    display:flex;
    justify-content:space-between;
    width: 100px;
  }

  .contentWrapper{
    display:flex;
    flex-wrap: wrap;
  }


      .inputWrapper {
        position: relative;
        width: 510px;
        height: 38px;
        margin: 10px 0 10px 0;
        & > * {
          position: absolute;
          &:nth-child(2) {
            left: 100px;
            width: 375px;
          }
          &:nth-child(3) {
            left: 285px;
          }
          &:nth-child(4) {
            left: 330px;
          }
        }
        .delete{
          position: relative;
          top:8px;
          left: 430px;
          display: inline;
          width: 100px;
          text-decoration: underline;
          cursor: pointer;
          font-size: 13px;
        }
        .add{
          left: 500px;
          height: 38px;
          width: 60px;
          border: none;
          text-transform: uppercase;
          color: white;
          background-color: #4A4A4A;
        }
      }
      .noteWrapper {
        width: 260px;
        margin-left: 150px;
        text-align: justify;
        font-size: 13px;
        & > span:first-child {
          color: #4a4a4a;
        }
        & > span:last-child {
          color: #9b9b9b;
        }
      }
      // .checkboxGroupWrapper {
      //   display: flex;
      //   & > *:first-child {
      //     width: 110px;
      //   }
      // }

      .textareaWrapper, .checkboxGroupWrapper {
        display: flex;
        font-size: 0;
        & > *:first-child {
          width: 150px;
        }
      }
}
