//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;


//Font-sizes
$middle-size: 15px;
$small-size: 13px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;


.HeaderNavLinks {
  display: flex;
  justify-content: space-between;
  width: 650px;
  height: 98px;
  a {
    display: flex;
    align-items: center;
    height: 100%;
    color: #4A4A4A;
    font-size: 15px;
    text-transform: uppercase;
    padding: 0 10px;
    &.active {
      font-weight: bold;
      border-top: 3px solid transparent;
      border-bottom: 3px solid #4A4A4A;
    }
  }
}