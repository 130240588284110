//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;


//Font-sizes
$middle-size: 15px;
$small-size: 13px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;


.wrapper {
  padding: 40px;
  // min-width: 1200px;
}

.edit_wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}

.titleWrapper {
  display: grid;
  grid-template-columns: max-content 1fr;
  justify-content: space-between;
  align-items: center;
  height: 42px;
}
.filters {
  display: flex;
  align-items: center;
  height: 50px;
  .filter {
    margin-right: 10px;
    cursor: pointer;
    color: #9b9b9b;
    font-size: 15px;
    &.active {
      color: #4a4a4a;
    }
  }
}
.contentWrapper {
  // max-width: 900px;
  padding-top: 22px;
  // min-width: 900px;
  display: flex;
  justify-content: space-between;
}
// .row {
//   display: flex;
//   align-items: center;
//   height: 42px;
// }
// .alert {
//   font-size: 12px;
//   margin-right: 20px;
// }
.alertSign {
  color: #d0021b;
}
.alertMeaning {
  color: #9b9b9b;
}
.subforms {
  width: 900px;
  min-width: 900px;
  & > * {
    margin-bottom: 40px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.snapshots {
  margin-left: 40px;
  & > * {
    margin-bottom: 10px;
  }
  .title {
    color: #4a4a4a;
  }
  .snapshot {
    color: #9b9b9b;
    cursor: pointer;
    &.active {
      color: #d0021b;
    }
  }
}
