//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;


//Font-sizes
$middle-size: 15px;
$small-size: 13px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;


.FileInput {
  position: relative;
  width: 215px;
  height: 215px;
  border: 1px solid #d8d8d8;
  border-radius: 2px;
  &.borderless {
    border: 1px solid transparent;
    border-radius: 0;
  }

  .preview {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #f5f5f5;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
    &.disabled {
      cursor: not-allowed;
    }
    
    &:hover .remove {
      display: flex;
    }
    
    .name {
      color: #9b9b9b;
      font-size: 13px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 90%;
    }
    
    .remove {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      display: none;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 30px;
      font-size: 13px;
      background-color: rgba(0, 0, 0, 0.05);
      color: #D0021B;
    }
    .placeholder {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      .circle {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80px;
        height: 80px;
        background-color: white;
        border-radius: 50%;
      }
      .text {
        position: absolute;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 30px;
        color: #7d7d7d;
        font-size: 13px;
      }
    }
  }
  input {
    display: none;
  }
  .error {
    position: absolute;
    color: #D0021B;
    font-size: 10px;
  }
}
