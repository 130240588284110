//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;


//Font-sizes
$middle-size: 15px;
$small-size: 13px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;


.TablePagination {
  display: flex;
  align-items: center;
  font-size: 13px;
  color: #7d7d7d;
  & > div {
    display: flex;
    align-items: center;
    margin-right: 40px;
    &:last-child {
      margin-right: 0;
      & > *:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  height: 26px;
  color: #292929;
  background-color: #f1efee;
  border: 1px solid #d8d8d8;
  font-size: 13px;
  // border: none;
}
