//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;


//Font-sizes
$middle-size: 15px;
$small-size: 13px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;


.wrapper {
  background-color: white;
  border: 1px solid #d8d8d8;
}
.backButtonWrapper {
  height: 60px;
  padding: 10px;
  border-bottom: 1px solid #d8d8d8;
}
.main {
  display: flex;
  align-items: stretch;
  & > * {
    flex: 0 0;
  }
  & > *:last-child {
    flex: 1 1;
  }
}
.line {
  align-self: stretch;
  border-left: 1px solid #d8d8d8;
}
