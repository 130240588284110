//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;


//Font-sizes
$middle-size: 15px;
$small-size: 13px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;


.row {
  display: grid;
  grid-template-columns: 140px max-content;
  align-items: center;
  grid-column-gap: 15px;
}

.title {
  font-weight: 700;
  margin: 10px 0 10px 0 ;
}

.rows {
  margin-left: 10px;
}
