//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;


//Font-sizes
$middle-size: 15px;
$small-size: 13px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;


.TableData {
  min-height: 240px;
  padding: 10px;
  background-color: white;
  border: 1px solid #D8D8D8;
  position: relative;
  .columnsWrapper {
    display: flex;
    .column {
      position: relative;
      &:first-child .cell:nth-child(2n + 1) {
        border-left: 1px solid $additional-color;
      }
      &:last-child .cell:nth-child(2n + 1) {
        border-right: 1px solid #D8D8D8;
      }
      .headerCell {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 46px;
        border: 1px solid transparent;
        padding: 0 12px 0 10px;
        color: #4a4a4a;
        overflow: hidden;
        font-size: 13px;
        font-weight: bold;
        white-space: nowrap;
        .headerCellInner {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
      .cell {
        position: absolute;
        display: flex;
        align-items: center;
        width: 100%;
        height: 46px;
        padding: 0 10px;
        background: white;
        font-size: 13px;
        color: #4a4a4a;
        overflow: hidden;
        white-space: nowrap;
        &:nth-child(2n + 1) {
          border-top: 1px solid #D8D8D8;
          border-bottom: 1px solid #D8D8D8;
        }
        .image {
          width: 38px;
          min-width: 38px;
          height: 38px;
          min-height: 38px;
          background-color: #ebebeb;
          border-radius: 50%;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        }
        .link {
          cursor: pointer;
          text-decoration: underline;
          // &:hover {
          //   text-decoration: underline;
          // }
        }
      }
    }
  }
  .spinnerWrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(74, 74, 74, 0.3);
    z-index: 1;
  }
  .error {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 12px;
    color: #D0021B;
  }
}
