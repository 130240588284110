//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;


//Font-sizes
$middle-size: 15px;
$small-size: 13px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;


.titleWrapper {
  display: grid;
  align-items: center;
  grid-template-columns: max-content 1fr;
  justify-content: space-between;
}

.rowsWrapper {
  margin-top: 24px;
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-column-gap: 50px;
}
.rowWrapper {
  display: grid;
  grid-template-columns: 115px max-content;
  align-items: center;
}
